<template>
	<div class="container" v-loading='loading' style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="width: 75px;display: inline-block;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" @keyup.enter.native="doorListFilter" placeholder="门店名称、编码" style="width: 250px;"
				 clearable></el-input>
			</div>
			<div class="filter-item">
				<label class="label">核销时间： </label>
				<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="doorListFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>
		</div>
		<div style="background:#F5F5F5;padding: 15px;margin-bottom:10px;font-size: 15px;">
			<span>店内支付-订单总额：{{OrderMoney}}</span> <span style="margin-left: 25px;">店内支付订单-退款单总额：{{RefundMoney}}</span>
		</div>

		<!-- 表格 -->
		<el-table :data="tableData" style="width: 100%" v-loading="loading">
			<el-table-column prop="ShopName" label="门店" width="150">
				<template slot-scope="scope">
					<div>{{scope.row.ShopName}}</div>
				</template>
			</el-table-column>
			<el-table-column label="到店支付订单">
				<el-table-column prop="OrderCount" label="订单数量"></el-table-column>
				<el-table-column prop="OrderMoney" label="订单金额"></el-table-column>
			</el-table-column>
			<el-table-column label="退款单">
				<el-table-column prop="RefundMoney" label="退款成功金额"></el-table-column>
			</el-table-column>
			<el-table-column label="操作" width="190">
				<template slot-scope="scope">
					<el-button type="text" @click="storeDetail(scope.row)">订单详情</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: right;">
			<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="Total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		dataStatisOfflinePayInit,
		dataStatisOfflinePaylist
	} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				loading: false,
				searchKey: '',
				starTime: '',
				endTime: '',
				OrderMoney: '',
				RefundMoney: '',
				exportUrl: config.EXPORT_URL,
				tableData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
			}
		},
		methods: {
			// 获取今日时间
			getNowDay() {
				let date = new Date();
				let year = date.getFullYear();
				let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
				let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
				this.starTime = year + '-' + month + '-' + day + ' ' + '00:00:00';
				this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';
			},
			storeDetail(record) {
				this.$router.push({
					path: '/door/TostorePayDetails',
					query: {
						Id: record.ShopId ? record.ShopId : 0,
						starTime: this.starTime,
						endTime: this.endTime
					}
				});

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 搜索
			doorListFilter() {
				this.currentPage = 1
				this.getList();
			},
			// 导出
			async exportFun() {
				try {
					this.loading = true;
					let url = this.exportUrl + '/pc/dataStatisOfflinePay/export?' +
						'&Keywords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						StartTime: this.starTime,
						EndTime: this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result1 = await dataStatisOfflinePayInit(data)
					if(result1.IsSuccess){
						this.OrderMoney = result1.Result.OrderMoney
						this.RefundMoney = result1.Result.RefundMoney
					}
					let result = await dataStatisOfflinePaylist(data)
					if(result.IsSuccess){
						this.Total = result.Result.Total;
						this.tableData = result.Result.Results || [];
					}
					
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
		},
		created() {
			this.getNowDay()
			// this.getInit()
			this.getList()
		}
	}
</script>

<style>
</style>
